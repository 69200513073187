import * as React from "react";
import { graphql } from "gatsby";
import Layout from "./layout";
import FullApp from "../FullApp";
import "../css/markdown.css";
import { Container, Divider, Grid, Segment } from "semantic-ui-react";
import '../css/article.css'
import SEO from "../components/seo";
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import MainTopBg from "../components/backgrounds/others/MainTopBg";


export default function Template(props) {
  const { markdownRemark } = props.data;
  const { html, frontmatter } = markdownRemark;
  const gatsbyImage = getImage(frontmatter.featuredImage.childImageSharp.gatsbyImageData);
  return (
    <FullApp>
      <Layout {...props} router={props} isDark={true}>
        <MainTopBg>
          <Segment basic id='article-container' style={{marginTop: 0}}>
            <SEO title={frontmatter.title} article
              description={frontmatter.description}
              image={frontmatter.featuredImage.publicURL}
            />
            <Segment basic>
              <Container>
                <Grid>
                  <Grid.Column width={16}>
                    <Segment basic textAlign='center'>
                      <p className='article-date'>{frontmatter.articleDate}</p>
                    </Segment>
                    <GatsbyImage image={gatsbyImage} alt={frontmatter.title}/>
                    <br/><br/>
                    <div dangerouslySetInnerHTML={{__html: html}}/>
                    <Divider hidden/>
                    <Segment id='article-author'>
                      <p><b>{frontmatter.articleAuthor}</b></p>
                      <p>{frontmatter.articleAuthorDescription}</p>
                    </Segment>
                    <Segment basic className='article-annotations'>
                      <Divider style={{width: "150px"}}/>
                      {frontmatter.articleNote.map((item) => (
                        <p key={item}>{item}</p>
                      ))}
                    </Segment>
                  </Grid.Column>
                </Grid>
              </Container>
            </Segment>
          </Segment>
        </MainTopBg>
      </Layout>
    </FullApp>
  );
}

export const pageQuery = graphql`
    query($path: String!) {
        markdownRemark(frontmatter: { path: { eq: $path } }) {
            html
            frontmatter {
                path
                title
                description
                articleDate
                articleAuthor
                articleAuthorDescription
                articleNote
                featuredImage {
                  childImageSharp {
                    gatsbyImageData(
                      width: 1200
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                  publicURL
                }
            }
        }
    }
`